<template>
    <aside
        class="left-sidebar"
        :class="
            Sidebar_drawer
                ? `show-sidebar bg-${
                      Theme === 'dark' ? 'gradient-dark' : SidebarColor
                  }`
                : `bg-${Theme === 'dark' ? 'gradient-dark' : SidebarColor}`
        "
        style="z-index: 201;"
        >
        <!-- style="min-width: 250px" -->
        <div :class="`bg-${navbarColor} navbar-header`">
            <!-- <LogoDark v-if="logoColor === 'white' && Theme !== 'dark'" />
            <LogoLight v-else /> -->
            <Logo />
            <span
                class="d-block d-lg-none close-sidebar-btn"
                @click="showMobileSidebar"
                ><i class="ti ti-close text-black" style="cursor: pointer;"></i
            ></span>
        </div>

        <VuePerfectScrollbar class="scrlbar">
            <ul class="nav flex-column mb-0">
                <template v-for="(route, i) in routes">
                    <!---Menu Title -->
                    <!-- {{ route }} -->
                    <li
                        class="nav-item blue-active-text"
                        :key="route.id"
                        v-if="route.header"
                    >
                        <div class="d-flex nav-small-cap">
                            <!-- <i :class="route.icon"></i> -->
                            <span class="hide-text">{{ route.title }}</span>
                        </div>
                    </li>
                    <!---Sub Menu -->
                    <li
                        class="nav-item"
                        :key="`r${route.id}`"
                        v-if="route.children"
                    >
                        <a
                            class="nav-link d-flex"
                            v-b-toggle="route.id"
                            :id="`header-${route.id}`"
                        >
                            <feather :type="route.icon"></feather>
                            <span class="hide-text">{{ route.title }}</span>
                            <i
                                class="ti ti-angle-down dd-arrow ml-auto hide-text"
                            ></i>
                        </a>
                        <b-collapse
                            :id="route.id"
                            accordion="sidebar-accordion"
                            :visible="$route.path.includes(route.name.trim())"
                            @show="showCollapse(route.id)"
                            @hide="hideCollapse(route.id)"
                        >
                            <ul class="nav flex-column sub-menu">
                                <template
                                    v-for="(subroute, i) in route.children"
                                >
                                    <!-- {{ subroute.name }} - {{ $route.path }} -->
                                    <li class="nav-item" :key="`sr${i}`">
                                        <router-link
                                            v-if="lc_status"
                                            :class="`nav-link d-flex ${
                                                (subroute.name ===
                                                    $route.path ||
                                                    $route.path.includes(
                                                        subroute.name
                                                    )) &&
                                                'router-link-exact-active router-link-active'
                                            }`"
                                            :to="`${subroute.name}`"
                                        >
                                            <i :class="subroute.icon"></i>
                                            <span class="hide-text">{{
                                                subroute.title
                                            }}</span>
                                        </router-link>
                                        <span
                                            v-else
                                            class="nav-link d-flex text-scondary"
                                            @click="sendNotification"
                                        >
                                            <i :class="subroute.icon"></i>
                                            <span class="hide-text">{{
                                                subroute.title
                                            }}</span>
                                        </span>
                                    </li>
                                </template>
                            </ul>
                        </b-collapse>
                    </li>
                    <!---Single Menu -->
                    <li
                        class="nav-item"
                        :key="i"
                        v-if="!route.children && !route.header"
                    >
                        <router-link
                            :to="`${route.to || route.name}`"
                            class="nav-link d-flex"
                        >
                            <feather :type="route.icon"></feather>
                            <span class="hide-text">{{ route.title }}</span>
                        </router-link>
                    </li>
                </template>
            </ul>
        </VuePerfectScrollbar>
    </aside>
</template>

<script>
// import LogoDark from '../logo-dark/LogoDark'
// import LogoLight from '../logo-light/LogoLight'
import Logo from '../logo/logo'
import { mapState, mapGetters } from 'vuex'
// import SidebarData from "./SidebarData";
// import ERPmenu from "./ERPmenu";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: 'VerticalSidebar',
    components: {
        // LogoDark,
        // LogoLight,
        Logo,
        VuePerfectScrollbar
    },
    data: () => ({
        initialShow: true
    }),
    mounted() {},
    computed: {
        lc_status() {
            // console.log(this.getUserData.lc)
            return this.getUserData?.lc?.valid
        },
        routes() {
            return this.getMenu
        },
        ...mapGetters('usuario', ['getMenu', 'getUserData']),
        ...mapState([
            'navbarColor',
            'Sidebar_drawer',
            'SidebarColor',
            'Theme',
            'getToken'
        ])
    },
    methods: {
        showCollapse(id) {
            const element = document.getElementById(`header-${id}`)
            element.classList.add('active-parent')
        },
        hideCollapse(id) {
            const element = document.getElementById(`header-${id}`)
            element.classList.remove('active-parent')
        },
        showMobileSidebar() {
            this.$store.commit('SET_SIDEBAR_DRAWER', false)
        },
        sendNotification() {
            this.$notify({
                group: 'general',
                title: 'Licencia expirada',
                text: `Su licencia ha expirado, por favor comuniquese con el proveedor de servicios para renovar su licencia`,
                ignoreDuplicates: true,
                duration: 5000,
                type: 'warn'
            })
        }
    }
}
</script>

<style>
.blue-active-text {
    color: #009bf5 !important;
}

.active-parent {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 1.5rem !important;
    background-color: #e5e9ef !important;
    color: #2f333b !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.5);
}

.active-parent > i {
    color: #009bf5 !important;
}
</style>
