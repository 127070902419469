var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${_vm.LayoutType} ${_vm.Theme}`},[(
            !_vm.setHorizontalLayout &&
            _vm.$route.path !== '/starting/crear-empresa' &&
            _vm.$route.path !== '/starting/crear-sede'
        )?_c('VerticalSidebar'):_vm._e(),_c('aside',{class:_vm.setHorizontalLayout == true ||
            _vm.$route.path === '/starting/crear-empresa' ||
            _vm.$route.path === '/starting/crear-sede'
                ? 'horizontal-page-wrapper'
                : 'page-wrapper'},[(!_vm.setHorizontalLayout)?_c('VerticalHeader'):_vm._e(),(_vm.setHorizontalLayout)?_c('HorizontalHeader'):_vm._e(),(
                _vm.setHorizontalLayout &&
                _vm.$route.path !== '/starting/crear-empresa' &&
                _vm.$route.path !== '/starting/crear-sede'
            )?_c('HorizontalSidebar'):_vm._e(),_c('div',{staticClass:"min-height",class:_vm.setHorizontalLayout == true
                    ? 'container-fluid content-wrapper'
                    : 'container-fluid content-wrapper'},[(_vm.$route.path !== '/starter')?_c('span',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]):_vm._e(),(_vm.$route.path !== '/starter')?_c('b-breadcrumb',{staticClass:"p-0"},[_c('b-breadcrumb-item',{attrs:{"href":"/","custom":""}},[_c('span',{class:`${_vm.Theme} font-weight-bold`},[_vm._v(_vm._s(_vm.$route.meta.module))])]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$route.name))])],1):_vm._e(),_c('router-view')],1),_c('Customizer'),_c('Footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }