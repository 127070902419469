<template>
  <b-navbar-brand href="#">
    <!-- Logo icon -->
    <b class="logo-icon">
      <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
      <!-- Light Logo icon -->
      <img src="../../../assets/images/logo-icon-softbusiness.png" alt="homepage" />
    </b>
    <!--End Logo icon -->
    <!-- Logo text -->
    <span class="logo-text ml-2">
      <!-- Light Logo text -->
      <img src="../../../assets/images/logo-text-softbusiness.png" alt="homepage" />
    </span>
  </b-navbar-brand>
</template>

<script>
export default {
  name: "LogoDark",
};
</script>
