<template>
    <div :class="`${LayoutType} ${Theme}`">
        <!-- ---------------------------------- -->
        <!--- Vertical Header part -->
        <!-- ---------------------------------- -->
        <VerticalSidebar
            v-if="
                !setHorizontalLayout &&
                $route.path !== '/starting/crear-empresa' &&
                $route.path !== '/starting/crear-sede'
            "
        />
        <aside
            :class="
                setHorizontalLayout == true ||
                $route.path === '/starting/crear-empresa' ||
                $route.path === '/starting/crear-sede'
                    ? 'horizontal-page-wrapper'
                    : 'page-wrapper'
            "
        >
            <VerticalHeader v-if="!setHorizontalLayout" />
            <HorizontalHeader v-if="setHorizontalLayout" />
            <HorizontalSidebar
                v-if="
                    setHorizontalLayout &&
                    $route.path !== '/starting/crear-empresa' &&
                    $route.path !== '/starting/crear-sede'
                "
            />
            <!-- <div v-if="this.getUltimaEmpresa && this.getUltimaSede" class="bg-light-success badge-font-1 text-gray d-block d-sm-flex justify-content-center align-items-center pl-4" style="position: sticky; margin-top: 10px">
        <i class="mr-2 mdi mdi-city"></i>
        {{ this.getUltimaEmpresa.razon_social }} 
        <feather type="chevron-right" size="1rem" class="text-secondary"></feather>
        {{ this.getUltimaSede.descripcion }}
      </div> -->
            <div
                class="min-height"
                :class="
                    setHorizontalLayout == true
                        ? 'container-fluid content-wrapper'
                        : 'container-fluid content-wrapper'
                "
            >
                <span
                    class="mb-0 font-weight-bold"
                    v-if="$route.path !== '/starter'"
                >
                    {{ $route.name }}
                </span>
                <b-breadcrumb class="p-0" v-if="$route.path !== '/starter'">
                    <b-breadcrumb-item href="/" custom>
                        <span :class="`${Theme} font-weight-bold`">{{
                            $route.meta.module
                        }}</span>
                    </b-breadcrumb-item>
                    <b-breadcrumb-item active>{{
                        $route.name
                    }}</b-breadcrumb-item>
                </b-breadcrumb>
                <router-view />
            </div>
            <Customizer />
            <Footer />
        </aside>
    </div>
</template>

<script>
import VerticalHeader from './vertical-header/VerticalHeader'
import HorizontalHeader from './horizontal-header/HorizontalHeader'
import HorizontalSidebar from './horizontal-sidebar/HorizontalSidebar'
import VerticalSidebar from './vertical-sidebar/VerticalSidebar'
import Customizer from './Customizer/Customizer'
import Footer from './footer/Footer'
import { mapMutations, mapState } from 'vuex'
export default {
    name: 'FullLayout',
    components: {
        VerticalHeader,
        VerticalSidebar,
        Customizer,
        HorizontalSidebar,
        HorizontalHeader,
        Footer
    },
    watch: {
        $route(to, from) {
            // console.log(to, from)
            this.setMenuVisibilityByRoute(to)
            this.removeQueryFilterStored(to, from)
        }
    },
    data: () => ({
        // cpath: this.currentRoute.path,
        isDocumentView: false,
        items: [
            {
                text: 'Dashboard',
                href: '/'
            },
            {
                text: '',
                active: true
            }
        ]
    }),
    computed: {
        ...mapState(['LayoutType', 'Theme', 'setHorizontalLayout']),
        currentRouteName() {
            return this.$route.name
        }
    },
    methods: {
        //define below method first.
        setMenuVisibilityByRoute(to) {
            const baseDocumentPath = '/home/administrativo/documentos'
            const regularExp = new RegExp(`^${baseDocumentPath}`, 'i')
            const { fullPath } = to

            this.isDocumentView = regularExp.test(fullPath)

            this.winWidth()
        },
        winWidth () {
            var w = window.innerWidth

            if (w < 992) {
                this.$store.commit('SET_LAYOUT_TYPE', 'mini-sidebar')
            }

            if (this.isDocumentView) {
                this.$store.commit('SET_LAYOUT_TYPE', 'mini-sidebar')
            }

            if (w > 992 && !this.isDocumentView) {
                this.$store.commit('SET_LAYOUT_TYPE', 'full-sidebar')
            }
        },
        removeQueryFilterStored({ fullPath: fullPathTo }, { fullPath: fullPathFrom }) {
            // si salgo de esta ruta se elimina esa query del storage
            const baseDocumentPath = '/home/administrativo/documentos'

            const regularExp = new RegExp(`^${baseDocumentPath}`, 'i')

            if (regularExp.test(fullPathFrom)) {
                const regularExpForm = new RegExp(`^${fullPathFrom}`, 'i')

                if (!regularExpForm.test(fullPathTo)) {
                    this.removeQueryFilter(fullPathFrom)
                }
            }
        },
        ...mapMutations(['removeQueryFilter'])
    },
    mounted() {
        //callback once mounted
        window.addEventListener("resize", this.winWidth)
        this.setMenuVisibilityByRoute(this.$route)
    }
}
</script>

<style></style>
