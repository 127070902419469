var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"left-sidebar",class:_vm.Sidebar_drawer
            ? `show-sidebar bg-${
                  _vm.Theme === 'dark' ? 'gradient-dark' : _vm.SidebarColor
              }`
            : `bg-${_vm.Theme === 'dark' ? 'gradient-dark' : _vm.SidebarColor}`,staticStyle:{"z-index":"201"}},[_c('div',{class:`bg-${_vm.navbarColor} navbar-header`},[_c('Logo'),_c('span',{staticClass:"d-block d-lg-none close-sidebar-btn",on:{"click":_vm.showMobileSidebar}},[_c('i',{staticClass:"ti ti-close text-black",staticStyle:{"cursor":"pointer"}})])],1),_c('VuePerfectScrollbar',{staticClass:"scrlbar"},[_c('ul',{staticClass:"nav flex-column mb-0"},[_vm._l((_vm.routes),function(route,i){return [(route.header)?_c('li',{key:route.id,staticClass:"nav-item blue-active-text"},[_c('div',{staticClass:"d-flex nav-small-cap"},[_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(route.title))])])]):_vm._e(),(route.children)?_c('li',{key:`r${route.id}`,staticClass:"nav-item"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(route.id),expression:"route.id"}],staticClass:"nav-link d-flex",attrs:{"id":`header-${route.id}`}},[_c('feather',{attrs:{"type":route.icon}}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(route.title))]),_c('i',{staticClass:"ti ti-angle-down dd-arrow ml-auto hide-text"})],1),_c('b-collapse',{attrs:{"id":route.id,"accordion":"sidebar-accordion","visible":_vm.$route.path.includes(route.name.trim())},on:{"show":function($event){return _vm.showCollapse(route.id)},"hide":function($event){return _vm.hideCollapse(route.id)}}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_vm._l((route.children),function(subroute,i){return [_c('li',{key:`sr${i}`,staticClass:"nav-item"},[(_vm.lc_status)?_c('router-link',{class:`nav-link d-flex ${
                                            (subroute.name ===
                                                _vm.$route.path ||
                                                _vm.$route.path.includes(
                                                    subroute.name
                                                )) &&
                                            'router-link-exact-active router-link-active'
                                        }`,attrs:{"to":`${subroute.name}`}},[_c('i',{class:subroute.icon}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(subroute.title))])]):_c('span',{staticClass:"nav-link d-flex text-scondary",on:{"click":_vm.sendNotification}},[_c('i',{class:subroute.icon}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(subroute.title))])])],1)]})],2)])],1):_vm._e(),(!route.children && !route.header)?_c('li',{key:i,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link d-flex",attrs:{"to":`${route.to || route.name}`}},[_c('feather',{attrs:{"type":route.icon}}),_c('span',{staticClass:"hide-text"},[_vm._v(_vm._s(route.title))])],1)],1):_vm._e()]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }