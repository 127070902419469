<template>
  <b-navbar-brand>
    <!-- Logo icon -->
    <b class="logo-icon">
      <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
      <!-- Dark Logo icon -->
      <img src="../../../assets/images/logo-light-icon-softbusiness.png" alt="homepage" />
    </b>
    <!--End Logo icon -->
    <!-- Logo text -->
    <span class="logo-text ml-2">
      <!-- dark Logo text -->
      <img src="../../../assets/images/logo-light-text-softbusiness.png" alt="homepage" />
    </span>
  </b-navbar-brand>
</template>

<script>
export default {
  name: "LogoLight",
};
</script>
