<template>
  <footer :class="`p-4 text-center bg-${Theme === 'dark' ? 'gradient-dark' : 'white'}`">
    © {{ new Date().getFullYear() }} — <a href="https://softbusiness.com.co/" target="_blank">Softbusiness S.A.S</a>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Footer",
  computed: {
    ...mapState(['Theme'])
  }
};
</script>

<style>
</style>