<template>
    <b-navbar-brand href="#">
        <!-- Logo icon -->
        <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Light Logo icon -->
            <img
                v-if="LayoutType !== 'mini-sidebar'"
                src="../../../assets/images/logo-softbusiness.png"
                alt="homepage"
                height="55rem"
            />

            <img
                v-else
                src="../../../assets/images/logo-mini-blue.png"
                alt="homepage"
                height="50rem"
            />
        </b>
    </b-navbar-brand>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Logo',
    computed: {
        ...mapState([
            'LayoutType'
        ])
    }
}
</script>
